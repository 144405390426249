// import external dependencies
import 'jquery'

// import everything from autoload
import './autoload/_bootstrap.js'; import './autoload/_emptySpace.js'; import './autoload/_hyphenate.js' // eslint-disable-line

// import vc-elements javascript files
import '../vc_elements/_new/main.js'; import '../vc_elements/accordion_container/main.js'; import '../vc_elements/accordion/main.js'; import '../vc_elements/button/main.js'; import '../vc_elements/carrer_teaser/main.js'; import '../vc_elements/competencies/main.js'; import '../vc_elements/green_section/main.js'; import '../vc_elements/home_hero/main.js'; import '../vc_elements/info_list/main.js'; import '../vc_elements/news_teaser/main.js'; import '../vc_elements/procedure/main.js'; import '../vc_elements/template_element/main.js' // eslint-disable-line

// import react habitat and react components
import './init/react'

// import local dependencies
import Router from './util/Router'
import common from './routes/common'
import home from './routes/home'
import aboutUs from './routes/about'

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
})

// Load Events
jQuery(document).ready(() => routes.loadEvents())
