import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_news_teaser()
})

function dhsv_vc_news_teaser() {
  $('.dhsv_vc_news_teaser').each(function() {
    let highestBox
    if ($('.js-content', this).length > 1) {
      highestBox = 0
    }
    $('.js-content', this).each(function() {
      if ($(this).height() > highestBox) {
        highestBox = $(this).height()
      }
    })
    $('.js-content', this).height(highestBox)
  })
}
