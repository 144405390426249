import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_green_section()
})

function dhsv_vc_green_section() {
  $('.dhsv_vc_green_section').each(function() {
    const logoNode = $(this)
      .closest('.body-container')
      .find('.brand')
    const menuNode = $(this)
      .closest('.body-container')
      .find('.nav-toggler')
    const bannerRight = $(this)
      .closest('.body-container')
      .find('.banner__right')
    const breadcrumbsContent = $(this)
      .closest('.body-container')
      .find('.breadcrumbs-content')

    const nodesArray = [logoNode, menuNode, bannerRight, breadcrumbsContent]
    const nodesSmallArray = [logoNode, bannerRight, breadcrumbsContent]

    nodesSmallArray.forEach(function(el) {
      el.hide().css({
        transform: 'translateY(50px)',
        opacity: '0',
      })
    })

    menuNode.hide().css({
      transform: 'translate(-50%, 50px)',
      opacity: '0',
    })

    setTimeout(() => {
      nodesArray.forEach(function(el) {
        el.show()
      })
    }, 100)

    setTimeout(() => {
      nodesSmallArray.forEach(function(el) {
        let timeout = 100
        setTimeout(() => {
          el.css({
            transform: 'translateY(0)',
            opacity: '1',
          })
        }, timeout)
        timeout += 100
      })
    }, 800)

    setTimeout(() => {
      menuNode.css({
        transform: 'translate(-50%, -50%)',
        opacity: '1',
      })
    }, 825)

    setTimeout(() => {
      $(this)
        .find('.green-section__inside-text')
        .addClass('animate')
      $(this)
        .find('.section_scroll')
        .addClass('animate')
    }, 1100)
  })
}
