import $ from 'jquery'
$('.accordion__item-title, .accordion__item .hidden-content .btn').on(
  'click',
  function() {
    const wrapper = $(this).closest('.accordion__items')
    const parent = $(this).closest('.accordion__item')
    if (parent.hasClass('active')) {
      $('.accordion__item')
        .removeClass('active')
        .removeClass('inactive')
      $('.accordion__item .hidden-content').slideUp()
    } else {
      $('.accordion__item').removeClass('active')
      $('.accordion__item .hidden-content').slideUp()
      wrapper.find('.accordion__item').addClass('inactive')
      parent.addClass('active').removeClass('inactive')
      parent.find('.hidden-content').slideDown()
    }
  }
)
