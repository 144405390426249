import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_competencies()
})

function dhsv_vc_competencies() {
  $('.dhsv_vc_competencies').each(function() {
    $('.js-item')
      .on('mouseenter', function() {
        $(this).addClass('active')
        $('.js-item')
          .not(this)
          .addClass('no-active')
      })
      .on('mouseleave', function() {
        $(this).removeClass('active')
        $('.js-item')
          .not(this)
          .removeClass('no-active')
      })
  })
}
