import cssVars from 'css-vars-ponyfill'
import ScrollOut from 'scroll-out'
import Typed from 'typed.js'
import '../util/bootstrap-toolkit'
import WOW from 'wow.js'

let typedBool = []

const dhsvCommon = {
  stickyBanner() {
    let scrollTop
    const body = $('body')

    function handleScroll() {
      scrollTop = $(window).scrollTop()

      if (scrollTop <= 5) {
        body.removeClass('sticky-nav')
      } else {
        body.addClass('sticky-nav')
      }
    }

    if ($('#wpadminbar').length) {
      $('.body-container').addClass('with-adminbar')
    }

    handleScroll()
    $(window).on('scroll', this.throttled(10, handleScroll))
  },
  throttled(delay, fn) {
    let lastCall = 0
    return function(...args) {
      const now = new Date().getTime()
      if (now - lastCall < delay) {
        return
      }
      lastCall = now
      return fn(...args)
    }
  },
  setBodyWidth() {
    cssVars({
      variables: { '--body-width': document.body.clientWidth + 'px' },
    })
  },
  smoothScroll(link, e) {
    let destination = 0

    if (e) e.preventDefault()
    var hash = false
    if (!link && window.location.hash) {
      hash = window.location.hash
    } else if (link && link.indexOf('#') == 0) {
      hash = link
    } else if (
      link &&
      location.pathname.replace(/^\//, '') ==
        link.pathname.replace(/^\//, '') &&
      location.hostname == link.hostname
    ) {
      hash = link.hash
    }

    if (hash && $(hash).length) {
      destination = $(hash).offset().top - 75
      history.replaceState(null, null, hash)
    }

    $('html, body').animate(
      {
        scrollTop: destination,
      },
      600
    )

    $(window).trigger('hashchange')
  },
  toggleContactInit() {
    $('body').on('click', '.js-contacts-toggler', function() {
      $(this).toggleClass('toggled')
      $('.contacts-modal')
        .fadeToggle(400)
        .toggleClass('is-open')
    })
  },
  primaryMenuInit() {
    $('body').on('click', '.js-nav-toggler', function() {
      $(this).toggleClass('toggled')
      if ($('.js-nav-primary:visible').length) {
        setTimeout(function() {
          $('.js-nav-primary')
            .css('opacity', 0)
            .hide()
            .removeClass('is-open')
        }, 10)
      } else {
        $('.js-nav-primary')
          .show()
          .addClass('is-open')
        setTimeout(function() {
          $('.js-nav-primary').css('opacity', 1)
        }, 10)
        $('.nav-link__title').removeClass('show')
      }
      $('.body-container').toggleClass('menu-open')
    })
    $('body').on('mousemove', '.js-nav-primary.is-open', function() {
      $('.nav-link__title').addClass('show')
    })
  },
  tooltipInit() {
    $('body').on('mouseenter', '.tooltip-link', function() {
      $(this)
        .next('.tooltip')
        .show(200)
    })
    $('body').on('mouseout', '.tooltip-link', function() {
      $(this)
        .next('.tooltip')
        .hide(200)
    })
  },
  menuItemHoverInit() {
    const navLink = $('.menu-item-has-children > .nav-link')
    if (navLink.length) {
      navLink.each(function() {
        if (typeof $(this).attr('href') === 'undefined') {
          $(this).addClass('hide-link')
        }
      })
      navLink.on('mouseenter', function() {
        if (typeof $(this).attr('href') !== 'undefined') {
          $(this)
            .closest('.menu-item-has-children')
            .addClass('hover')
        }
      })
      navLink.on('mouseout', function() {
        $(this)
          .closest('.menu-item-has-children')
          .removeClass('hover')
      })
    }
  },
  scrollOutInit() {
    ScrollOut({
      targets: '.scrollOut',
      threshold: 0.15,
      once: true,
    })
  },
  typedHandler() {
    const typedNode = $('.wpb_text_column.typing_effect')
    let typed = []
    typedNode.each(function(index, el) {
      const typedContent = $(el)
        .find('.typed_content')
        .html()

      let options = {
        strings: [typedContent],
        typeSpeed: 35,
      }
      typed[index] = new Typed($(el).find('.typed_output')[0], options)
      typed[index].destroy()
    })

    setTimeout(function() {
      $(window).trigger('scroll')
    }, 10)

    $(window).scroll(() => {
      typed.forEach(function(instance, index) {
        if (
          typedBool[index] != false &&
          $(instance.el)
            .closest('.scrollOut')
            .attr('data-scroll') === 'in'
        ) {
          typedBool[index] = false
          instance.reset()
        }
      })
    })
  },
  // greenPageAnimation() {
  //   function petrolbackgroundAnimation() {
  //     if ($('.green-transition-animation').length) {
  //       $('body').removeClass('body-transparent')
  //       $('.green-transition-animation')
  //         .addClass('show')
  //         .css('top', '0')
  //
  //       setTimeout(function() {
  //         $('.white-body-wrapper').addClass('hide')
  //       }, 900)
  //
  //       setTimeout(function() {
  //         $('.green-transition-animation')
  //           .removeClass('show')
  //           .addClass('hide')
  //           .css('top', '150vh')
  //       }, 1100)
  //
  //       setTimeout(function() {
  //         $('.green-transition-animation').hide()
  //       }, 1500)
  //     }
  //   }
  //
  //   petrolbackgroundAnimation()
  //
  //   $('.js-nav-toggler').click(() => {
  //     $('.green-transition-animation').css('top', '-150vh')
  //
  //     setTimeout(function() {
  //       $('.green-transition-animation').show()
  //     }, 50)
  //
  //     setTimeout(function() {
  //       petrolbackgroundAnimation()
  //     }, 300)
  //   })
  // },
  fontColorBarsAnimation() {
    if ($(".info-list-animation[data-scroll='in']").length) {
      setTimeout(function() {
        $(".info-list-animation[data-scroll='in']").addClass('animate')
      }, 2000)
    }
  },
  mobileBtnWidth() {
    const currentBreakpoint = window.bootstrapToolkit.current()
    if (currentBreakpoint === 'sm' || currentBreakpoint === 'xs') {
      const btnContainerWidth = $('.body-container').width()
      $('.btn, button[type="submit"]')
        .not('.btn-link, .text-slider__nav .btn')
        .each(function() {
          $(this).css('min-width', btnContainerWidth / 2)
        })
    }
  },
  nextSectionScroll() {
    //next-section anchor scrolling
    $('.next-section').on('click', function() {
      const sectionHeight = $(this)
        .closest('.section_next_scroll')
        .outerHeight()
      $('.js-scroll-section').addClass('active')
      const breadcrumbsNode = $('.breadcrumbs')
      let breadcrumbsHeight = 0
      if (breadcrumbsNode.length) {
        breadcrumbsHeight = breadcrumbsNode.outerHeight()
      }
      $('html, body').animate(
        {
          scrollTop: sectionHeight + breadcrumbsHeight,
        },
        500
      )
    })

    //line animation
    $('.next-section').each(function(index, element) {
      const lineElement = $(element)
      let scrollPercentage = undefined
      const velocity = 0.25
      $(window).on('scroll', function() {
        var offset = lineElement.offset().top - $(window).height() / 1.5
        if ($(window).scrollTop() > offset) {
          scrollPercentage =
            ($(window).scrollTop() - offset) / lineElement.height() * velocity
          if (scrollPercentage > 1) {
            lineElement.find('.line').css('height', lineElement.height())
          } else {
            lineElement
              .find('.line')
              .css('height', lineElement.height() * scrollPercentage)
          }
        } else {
          lineElement.find('.line').css('height', 0)
        }
      })
    })
  },
  vowAnimation() {
    const wow = new WOW({
      boxClass: 'wpb_animate_when_almost_visible',
      animateClass: 'animated',
      offset: 0,
      mobile: false,
      live: true,
      scrollContainer: null,
    })
    setTimeout(function() {
      wow.init()
    }, 1150)
  },
  splitIntoWords(className) {
    if ($(className).length) {
      Array.from($(className)).forEach(function(element) {
        const words = $(element)
          .text()
          .trim()
          .split(' ')
        words.map(function(el, i) {
          words[i] = `<span class="tooltip-word">${el} </span>`
        })
        $(element).html(words.join(''))
      })
    }
  },
  init() {
    this.stickyBanner()
    this.primaryMenuInit()
    this.toggleContactInit()
    this.setBodyWidth()
    this.tooltipInit()
    this.menuItemHoverInit()
    this.scrollOutInit()
    this.typedHandler()
    // this.greenPageAnimation()
    this.fontColorBarsAnimation()
    this.mobileBtnWidth()
    this.nextSectionScroll()
    this.vowAnimation()
    this.splitIntoWords('.js-tooltip')

    $(window).scroll(() => {
      this.fontColorBarsAnimation()
    })

    if (window.location.hash) {
      this.smoothScroll(window.location.hash)
    }

    $('body').on(
      'click',
      'a[href^="#"]:not([href="#"],[href="#0"],[href^="#fancy-"],[data-toggle],[role="tab"],[data-vc-accordion],[data-vc-tabs])',
      e => {
        this.smoothScroll($(e.currentTarget).attr('href'), e)
      }
    )
  },
  onResize() {
    $(window).on('resize', () => {
      this.setBodyWidth()
      this.mobileBtnWidth()
    })
  },
}

export default {
  init() {
    // JavaScript to be fired on all pages
    dhsvCommon.init()
    dhsvCommon.onResize()
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
