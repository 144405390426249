import $ from 'jquery'
import Typed from 'typed.js'

$(document).ready(function() {
  dhsv_vc_home_hero()
})

function dhsv_vc_home_hero() {
  $('.home-hero__inside').removeClass('show')
  $('.dhsv_vc_home_hero').each(function() {
    const welcomeBool = sessionStorage.getItem('welcomeShown')
    if (welcomeBool != 'true') {
      $('.green-transition-animation').hide()
      $('.white-body-wrapper').addClass('hide')

      const typedContentTop = $('.home-hero__top-typed').data('type')
      const typedContentMiddle = $('.home-hero__middle-typed').data('type')
      const typedContentBottom = $('.home-hero__bottom-typed').data('type')

      if (
        typeof typedContentTop !== 'undefined' ||
        typeof typedContentMiddle !== 'undefined' ||
        typeof typedContentBottom !== 'undefined'
      ) {
        const hideCursor = el => {
          setTimeout(function() {
            $(`.${el.el.className}`)
              .next('.typed-cursor')
              .hide()
          }, 250)
        }

        setTimeout(function() {
          $('.home-hero__animation').css('display', 'flex')
        }, 10)

        const typeSpeed = 40
        const optionsTop = {
          strings: [typedContentTop],
          typeSpeed: typeSpeed,

          onComplete: self => {
            hideCursor(self)
            $('.home-hero__middle-line-text').addClass('show')
            setTimeout(function() {
              new Typed('.home-hero__middle-typed', optionsMiddle)
            }, 500)
          },
        }
        new Typed('.home-hero__top-typed', optionsTop)

        const optionsMiddle = {
          strings: [typedContentMiddle],
          typeSpeed: typeSpeed,

          onComplete: self => {
            hideCursor(self)
            $('.home-hero__bottom-line-text').addClass('show')
            setTimeout(function() {
              new Typed('.home-hero__bottom-typed', optionsBottom)
            }, 500)
          },
        }

        const optionsBottom = {
          strings: [typedContentBottom],
          typeSpeed: typeSpeed,

          onComplete: self => {
            hideCursor(self)
            setTimeout(function() {
              $('.home-hero__forth-line').addClass('show')
            }, 500)
            setTimeout(function() {
              $('.home-hero__fifth-line').addClass('show')
            }, 1000)
            setTimeout(function() {
              $('.home-hero__animation').addClass('hide')
            }, 2600)

            setTimeout(function() {
              $('.home-hero__inside').addClass('show')
              $('.home-hero__inside-fade-first').addClass('show')
            }, 2700)
            setTimeout(function() {
              $('.home-hero__inside-fade-second').addClass('show')
            }, 3200)
            setTimeout(function() {
              $('.home-hero__inside-text-bottom').addClass('show')
            }, 3700)
          },
        }
      }

      sessionStorage.setItem('welcomeShown', 'true')
    } else {
      setTimeout(function() {
        $('.home-hero__inside').addClass('show')
        $('.home-hero__inside-fade-first').addClass('show')
      }, 500)
      setTimeout(function() {
        $('.home-hero__inside-fade-second').addClass('show')
      }, 1000)
      setTimeout(function() {
        $('.home-hero__inside-text-bottom').addClass('show')
      }, 1500)
    }

    $('.language-list .dropdown-item').one('click', function() {
      const languageChange = sessionStorage.getItem('languageChange')
      if (languageChange != 'true') {
        sessionStorage.setItem('welcomeShown', 'false')
      }
      sessionStorage.setItem('languageChange', 'true')
    })
  })
}
